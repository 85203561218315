<template>
  <a-modal
    :visible="visible"
    :dialog-style="{ top: '10px' }"
    :title="title"
    :loading="loading"
    width="50%"
    v-highlight
    @cancel="handleCancel"
  >
    <a-card class="card" :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="发行" prop="seller">
                <a-input v-model="queryParam.seller" placeholder="请输入发行" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
          <a-row>
            <span v-for="(tag, index) in bindScriptNames" :key="tag">
              <a-tag :closable="true" @close="handleClose(tag, index)">
                {{ tag }}
              </a-tag>
            </span>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="isInclude" slot-scope="text, record">
          {{ record.isInclude ? '是' : '否' }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleBind(record)" v-if="!record.isInclude" v-hasPermi="['script:script:remove']">
            <a-icon type="delet" />选择
          </a>
        </span>
        <template slot="cover" slot-scope="text, record">
          <div>
            <img v-if="record.cover" :src="record.cover" style="width:60px;height:62px;" @preview="handlePreview"/>
          </div>
        </template>
        <span slot="sellers" slot-scope="text, record" :title="record.sellers.map(item => item.name).join(', ')">
          {{ record.sellers.map(item => item.name).join(', ') }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <div slot="footer" class="dialog-footer">
      <a-button @click="handleCancel">取 消</a-button>
      <a-button type="primary" @click="handleOk">收 录</a-button>
    </div>
  </a-modal>
</template>

<script>
import { listShopScript, includeScript } from '@/api/script/script'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'BatchBindScriptModal',
  props: {
  },
  components: {
  },
  mixins: [tableMixin],
  data () {
    return {
      title: '收录剧本',
      visible: false,
      bindScriptIds: [],
      bindScriptNames: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 预览封面
      previewVisible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: null,
        cover: null,
        name: null,
        seller: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '剧本名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剧本封面',
          dataIndex: 'cover',
          scopedSlots: { customRender: 'cover' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发行',
          dataIndex: 'sellers',
          scopedSlots: { customRender: 'sellers' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否收录',
          dataIndex: 'isInclude',
          scopedSlots: { customRender: 'isInclude' },
          width: 120,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 170,
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 120,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      // bindScriptIds: [],
      // bindScriptNames: [],
      tags: ['Unremovable', 'Tag 2', 'Tag 3Tag 3Tag 3Tag 3Tag 3Tag 3Tag 3']
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleOk() {
      console.log(this.bindScriptIds)
      if (this.bindScriptIds.length === 0) {
        this.$message.warning("请先选择要收录的剧本！")
        return
      }
      const data = {
        shopId: this.queryParam.shopId,
        scriptIds: this.bindScriptIds
      }
      this.loading = true
      includeScript(data).then(res => {
        if (res.success) {
          this.$message.success("批量收录剧本成功")
          this.bindScriptIds = []
          this.bindScriptNames = []
          this.getList()
        } else {
          this.$message.error("批量收录剧本失败：" + res.message)
        }
        this.loading = false
      }).catch(err => {
        this.$message.error(err.message)
        this.loading = false
      })
    },
    handleCancel() {
      this.resetQuery()
      this.bindScriptIds = []
      this.bindScriptNames = []
      this.visible = false
    },
    handleClose (removedTag, index) {
      if (index !== -1) {
        this.bindScriptIds.splice(index, 1)
        this.bindScriptNames.splice(index, 1)
      }
    },
    /** 查询剧本列表 */
    getList () {
      this.loading = true
      listShopScript(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handlePreview(file) {
      console.log('打开了')
      this.previewAvatar = file.url || file.preview
      this.previewVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: null,
        seller: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    /** 绑定按钮操作 */
    handleBind (row) {
      const ids = row.id || this.ids
      const scriptName = row.name
      if (!this.bindScriptIds.includes(ids)) {
        this.bindScriptIds.push(ids)
        this.bindScriptNames.push(scriptName)
      }
      // this.visible = false
      // this.$emit('select', ids)
      // this.$emit('selectname', scriptName)
    },
    /** 导出按钮操作 */
    handleExport () {
      const that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('script/script/export', {
            ...that.queryParam
          }, `script_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    open(scriptRow) {
      this.title = '收录剧本 - ' + scriptRow.shopName
      this.queryParam.shopId = scriptRow.id
      this.visible = true
      this.getList()
    }
  }
}
</script>

<style scoped lang="css">

</style>
